import { Flex, Heading } from "theme-ui"
import TeamCard from "components/teamCard"

import Member1 from "images/mr-Hilal.jpg"
import Member2 from "images/mr-Mohamed.jpg"
import Member3 from "images/mr-Oussema.jpg"

const teamData = [
  {
    id: 1,
    altText: "Mr Hilal OUALI",
    image: Member1,
    name: "Mr Hilal OUALI",
    designation: "Directeur technique",
    email: "hilal.ouali@acbusiness.com.tn",
    description:
      "Expert comptable membre de l’OECT ayant une expérience professionnelle de plus de 20 ans. Il a conduit plusieurs missions de corporate finance et de conseil et audit.",
  },
  {
    id: 2,
    altText: "Mr Mohamed MIGAOU",
    name: "Mr Mohamed MIGAOU",
    image: Member2,
    designation: "Directeur Exécutif",
    email: "med.migaou@acbusiness.com.tn",
    description:
      "Expert comptable membre de l’OECT ayant une expérience professionnelle de plus de 20 ans dans les domaines de l’audit et du conseil aux entreprises.",
  },
  {
    id: 3,
    altText: "Mr Oussema BAHLOUL",
    name: "Mr Oussema BAHLOUL",
    image: Member3,
    designation: "Gérant",
    email: "oussema.acb@gnet.tn",
    description:
      "Expert comptable, ayant une expérience professionnelle de plus de 10 ans dans les domaines d’audit, du Conseil, d’ingénierie et d’organisation des entreprises.",
  },
]

export default function TeamSection() {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        overflow: "hidden",
        py: 5,
        px: 1,
      }}
    >
      <Heading as="h4" sx={{ variant: "headings.subHeader.light" }}>
        Quality of work and Integrity of services
      </Heading>
      <Heading sx={{ m: 3, mr: "auto", ml: "auto" }}>Notre Équipe</Heading>
      <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
        <Flex>
          <TeamCard
            key={`team--key${teamData[0].id}`}
            image={teamData[0].image}
            description={teamData[0].description}
            email={teamData[0].email}
            altText={teamData[0].altText}
            name={teamData[0].name}
            designation={teamData[0].designation}
          />
          <TeamCard
            key={`team--key${teamData[1].id}`}
            image={teamData[1].image}
            description={teamData[1].description}
            email={teamData[1].email}
            altText={teamData[1].altText}
            name={teamData[1].name}
            designation={teamData[1].designation}
          />
        </Flex>
        <TeamCard
          key={`team--key${teamData[2].id}`}
          image={teamData[2].image}
          description={teamData[2].description}
          email={teamData[2].email}
          altText={teamData[2].altText}
          name={teamData[2].name}
          designation={teamData[2].designation}
        />
      </Flex>
    </Flex>
  )
}
