import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Container, Flex, Heading } from "theme-ui"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ClientsSection = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "clients.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, breakpoints: [200, 340, 520, 900])
        }
      }
    }
  `)
  const image = getImage(data.file)

  return (
    <Container
      sx={{
        position: "relative",
        pt: [2, 5],
        pb: 5,
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          width: "full",
        }}
      >
        <Heading as="h4" sx={{ variant: "headings.subHeader.light" }}>
          Trusted By Many
        </Heading>
        <Heading sx={{ m: 3, mr: "auto", ml: "auto" }}>
          Nos principales Références
        </Heading>
      </Flex>
      <GatsbyImage sx={{ maxWidth: 900, mx: "auto" }} image={image} />
    </Container>
  )
}

export default ClientsSection
