import * as React from "react"
// import { FaAward } from "@react-icons/all-files/fa/FaAward"
// import { FaAddressBook } from "@react-icons/all-files/fa/FaAddressBook"
// import { FaBriefcase } from "@react-icons/all-files/fa/FaBriefcase"
// import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding"
// import { FaBoxes } from "@react-icons/all-files/fa/FaBoxes"
// import { FaBusinessTime } from "@react-icons/all-files/fa/FaBusinessTime"
// import { FaCalculator } from "@react-icons/all-files/fa/FaCalculator"
// import { FaCrosshairs } from "@react-icons/all-files/fa/FaCrosshairs"
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"
import PatternBG from "images/patternBG.png"

import { Container, Flex, Heading, Text } from "theme-ui"
import { motion } from "framer-motion"

const ServicesSection = () => (
  <Container
    sx={{
      backgroundColor: "gray.7",
      backgroundImage: `url(${PatternBG})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
      position: "relative",
      py: 5,
    }}
  >
    <Flex
      sx={{
        flexDirection: "column",
        width: "full",
      }}
    >
      <Heading as="h4" sx={{ variant: "headings.subHeader" }}>
        More than Quality
      </Heading>
      <Heading sx={{ m: 3, mr: "auto", ml: "auto", color: "white" }}>
        Domaines De Spécialisation
      </Heading>
      <motion.div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: ["flex-start", "flex-start", "center"],
          flexWrap: "wrap",
          gap: 3,
          p: 24,
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        variants={{
          visible: {
            opacity: 1,
            rotate: 0,
            transition: { staggerChildren: 0.3 },
          },
          hidden: { opacity: 0, rotate: 10 },
        }}
      >
        {[
          "Expertise comptable (audit et commissariat aux apports…)",
          "Organisation d'entreprise",
          "Conseil d'entreprise",
          "Audit des coûts pétroliers",
          "Implémentation des sociétés étrangères",
          "Mise en place de système d'information et de contrôle. Implémentation des ERP",
          "Programme de privatisation et de mise à niveau",
          "Comptabilité et assistance fiscale, juridique et sociale",
          "Formation IFRS, informatique…",
          "Inventaires physiques, conseils & due diligences dans le secteur de télécommunication et le secteur pétrolier",
        ].map(value => (
          <motion.div
            key={value}
            variants={{
              visible: { opacity: 1, rotate: 0 },
              hidden: { opacity: 0, rotate: 10 },
            }}
            transition={{ duration: 1 }}
          >
            <FaCheckCircle sx={{ fontSize: 1, color: "icons" }} />
            &nbsp;
            <Text sx={{ color: "white" }}>{value}</Text>
          </motion.div>
        ))}
      </motion.div>
    </Flex>
  </Container>
)

export default ServicesSection
/* <FaAward sx={{ fontSize: 7 }} />
  <FaAddressBook sx={{ fontSize: 7 }} />
  <FaAward sx={{ fontSize: 7 }} />
  <FaBusinessTime sx={{ fontSize: 7 }} />
  <FaCalculator sx={{ fontSize: 7 }} />
  <FaBoxes sx={{ fontSize: 7 }} />
  <FaBriefcase sx={{ fontSize: 7 }} />
  <FaBuilding sx={{ fontSize: 7 }} />
  <FaCrosshairs sx={{ fontSize: 7 }} /> */
