import * as React from "react"
import TextLoop from "components/TextLoop"

import Layout from "components/layout"
import Seo from "components/seo"
import ValuesSection from "sections/values"
import IntroductionSection from "sections/introduction"
import ServicesSection from "sections/services"
import TeamSection from "sections/team"
import ClientsSection from "sections/clients"
import Header from "sections/header"

const quotes = [
  "Le bon comportement est la meilleure chose qui ait été donnée aux gens",
  "Le lecteur idéal lit toute la littérature comme si elle était anonyme",
  "Pour les humains, la perfection est inaccessible, l'excellence oui",
  "Fais ton travail du mieux que tu peux, en acceptant les erreurs inévitables",
  "Entre le possible et l'impossible se mesure l'inteligence et la volonté des hommes",
  "Qui veut aller loin ménage sa monture",
]

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Header />
    {/* <Gallery /> */}
    <IntroductionSection />
    <ValuesSection />
    <ServicesSection />
    <TeamSection />
    <ClientsSection />
    <TextLoop texts={quotes} />
    {/* <div className={styles.textCenter}>
      <StaticImage
        src="../images/example.png"
        loading="eager"
        width={64}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-3)` }}
      />
      <h1>
        Welcome to <b>Gatsby!</b>
      </h1>
      <p className={styles.intro}>
        <b>Example pages:</b>{" "}
        {samplePageLinks.map((link, i) => (
          <React.Fragment key={link.url}>
            <Link to={link.url}>{link.text}</Link>
            {i !== samplePageLinks.length - 1 && <> · </>}
          </React.Fragment>
        ))}
        <br />
        Edit <code>src/pages/index.js</code> to update this page.
      </p>
    </div> */}
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
