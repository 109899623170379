import { Text, Heading, Image, Link, Flex } from "theme-ui"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"

export default function TeamCard({
  image,
  description,
  name,
  designation,
  email,
}) {
  return (
    <Flex sx={styles.card}>
      <Image src={image} alt={name} sx={styles.memberThumb} />
      <Flex sx={styles.infoWrapper}>
        <Heading className="info__name" sx={styles.infoWrapper.name}>
          {name}
        </Heading>
        <Flex className="info__designation" sx={styles.infoWrapper.designation}>
          {designation}
        </Flex>
      </Flex>
      <Text
        className="info__description"
        sx={{ ...styles.infoWrapper.description }}
      >
        {description}
      </Text>
      <Flex sx={styles.socialShare} className="social__share">
        <Link href={`mailto:${email}`}>
          <FaEnvelope />
        </Link>
      </Flex>
    </Flex>
  )
}

const styles = {
  card: {
    alignItems: "center",
    flexDirection: "column",
    borderColor: "red",
    py: [0, 3, 5, 4],
    px: [2, 2, 2, 6],
    transition: "ease-in-out 0.4s",
    borderRadius: "8px",
    position: "relative",
    "&:hover": {
      boxShadow: ["none", null, "0 4px 10px rgba(39, 83, 123, 0.12)"],
      ".info__name": {
        color: "primary",
      },
      ".info__designation": {
        color: "primary",
      },
      ".info__description": {
        color: "primary",
      },
      ".social__share": {
        opacity: 1,
        a: {
          my: 0,
          py: [0, null, 1],
        },
      },
    },
  },
  infoWrapper: {
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    mt: [3, null, 4, null],
    name: {
      minHeight: "40px",
      fontSize: [1, 2, 3, null, null, 4],
      fontWeight: "bold",
      lineHeight: [1.25, 1.35],
      transition: "color 0.25s",
      mb: 1,
    },
    designation: {
      fontSize: ["14px", null, null, 2],
      fontWeight: "body",
      lineHeight: "heading",
      color: "text",
      textAlign: "center",
      alignItems: "center",
      transition: "color 0.25s",
      height: "40px",
    },
    description: {
      fontSize: ["12px", null, null, 1],
      fontWeight: "body",
      lineHeight: "body",
      textAlign: "center",
      mt: 2,
      width: "full",
      maxWidth: 300,
      color: "text",
      transition: "color 0.25s",
    },
  },
  memberThumb: {
    width: ["70px", "80px", "100px", null, null, "130px"],
    height: ["70px", "80px", "100px", null, null, "130px"],
    flexShrink: 0,
    border: "2px solid",
    borderColor: "primary",
    borderRadius: "50%",
  },
  socialShare: {
    position: ["relative", "relative", "absolute", "absolute", "absolute"],
    right: [0, 0, 4, 4, 5],
    bottom: [0, 0, "18px", "18px", 5],
    width: ["full", "full", "auto", "auto", "auto", "auto"],
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.25s",
    opacity: [1, 1, 0],
    pt: 2,
    mt: "auto",
    a: {
      fontSize: [0, 1, 2, 2],
      color: ["text", null, "primary"],
      lineHeight: "1em",
      my: [0, null, "-2px"],
      px: 1,
      transition: "all 0.25s",
      "&:hover": {
        color: ["primary", "primary", "text", "text", "text"],
      },
    },
  },
}
