import AnimatedBG from "components/AnimatedBG"
import { Flex, Image, Text, Box } from "theme-ui"
import logo from "images/Logo_ACB.png"
import { motion } from "framer-motion"
import React from "react"

const markerBefore = {
  content: "",
  background: "primary",
  width: "4.5rem",
  height: "1.5rem",
  display: "inline-block",
  left: "-3rem",
  position: "absolute",
  zIndex: -1,
}

const Flash = ({ sxProp, elements = [], duration = 3, delay = 0 }) => {
  return (
    <Flex sx={{ ...sxProp, position: "relative" }}>
      {elements.map((string, index) => (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: [0, 1, 1, 0],
            transition: {
              duration,
              times: [0, 0.15, 0.85, 1],
              ease: "easeInOut",
              repeatDelay: duration * (elements.length - 1),
              delay: index * duration + delay,
              repeat: Infinity,
            },
          }}
          sx={{
            position: "absolute",
            fontSize: [20, 30, 40],
            fontWeight: "bold",
            right: 0,
            textAlign: ["center", "right"],
          }}
          key={string}
        >
          {string}
        </motion.div>
      ))}
    </Flex>
  )
}

export default function Header() {
  return (
    <Flex
      sx={{ width: "full", position: "relative", minHeight: [300, 350, 500] }}
    >
      <AnimatedBG />
      <Flex
        sx={{
          position: "absolute",
          top: "30%",
          width: "full",
          justifyContent: "space-around",
        }}
      >
        <Flex sx={{ flexDirection: "column" }}>
          <motion.h1
            sx={{
              textAlign: "center",
              fontSize: [30, 50, 60, 70, 90],
              left: 0,
              right: 0,
              mb: [20, 20, 50],
              top: "40%",
            }}
            animate={{ x: [-150, 0], opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            initial={{ opacity: 0, x: 1 }}
          >
            <Text>
              Advisory & Certifying <br /> in{" "}
              <Text sx={{ color: "primary" }}>Business</Text>
            </Text>
          </motion.h1>
          <Flash
            sxProp={{ width: "full", alignSelf: "end" }}
            elements={[
              "Bureau d'Expertise Comptable",
              "Commissariat aux Comptes",
              "Expertise & Conseil",
              "Partenaire pour des solutions d'excellence",
            ]}
            delay={1}
          />
        </Flex>

        <Flex
          sx={{
            display: "none",
            "@media screen and (min-width: 1406px)": {
              display: "flex",
            },
          }}
        >
          <Box sx={{ width: 500 }}>
            <Image
              sx={{
                height: "full",
              }}
              alt="ACB"
              src={logo}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}
