import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FaArrowCircleRight } from "@react-icons/all-files/fa/FaArrowCircleRight"

import { Flex, Heading, Text } from "theme-ui"
import { motion } from "framer-motion"
import bannerBg from "images/bannerBg.png"

const IntroductionSection = () => (
  <Flex
    sx={{
      width: "full",
      px: [0, "15%"],
      flexDirection: ["column", "column", "column", "row"],
      py: 5,
      backgroundColor: "orange",
      backgroundImage: `url(${bannerBg})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
    }}
  >
    <Flex
      sx={{
        flexDirection: "column",
        flex: 1,
        p: 3,
        justifyContent: "space-around",
      }}
    >
      <Heading
        sx={{
          m: 3,
          mx: "auto",
          color: "white",
        }}
      >
        Advisory & Certifying in Business
      </Heading>

      <Text sx={{ color: "white" }}>
        Advisory & Certifying in Business (ACB) est une société d'expertise
        comptable inscrite au tableau de l'Ordre des Experts Comptables de
        Tunisie (OECT). Elle a été fondée par Messieurs Hilal OUALI , Mohamed
        MIGAOU & Oussema BAHLOUL Experts Comptables membres de l'OECT
        respectivement depuis 2008, 2006 et 2018.
      </Text>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        variants={{
          visible: {
            opacity: 1,
            scale: 1,
            transition: { staggerChildren: 0.5 },
          },
          hidden: { opacity: 0, scale: 0 },
        }}
      >
        {[
          "Audit & services de certification",
          "Assistance juridique et fiscale",
          "Externalisation des fonctions comptables et financières",
          "Corporate finance",
          "Organisation et amélioration des process de gestion et de pilotage",
        ].map(service => (
          <motion.div
            sx={{
              fontSize: 0,
              mt: 1,
              color: "white",
            }}
            key={service}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
            <FaArrowCircleRight sx={{ color: "primary" }} /> {service}
          </motion.div>
        ))}
      </motion.div>
    </Flex>
    <Flex sx={{ flex: 1, p: 3 }}>
      <StaticImage
        src="../images/advisory-certifying-business.jpg"
        alt="acb"
        placeholder="blurred"
      />
    </Flex>
  </Flex>
)

export default IntroductionSection
