import * as React from "react"
import { motion } from "framer-motion"

import { Donut, Flex, Heading, Text } from "theme-ui"

const MotionFlex = motion(Flex)

const ValuesSection = () => {
  const isMobile = typeof window !== "undefined" && window.innerWidth < 600
  let variants = {
    hover: {
      y: -10,
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    },
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        overflow: "hidden",
        py: 5,
      }}
    >
      <Heading as="h4" sx={{ variant: "headings.subHeader.light" }}>
        Quality & Integrity
      </Heading>
      <Heading sx={{ m: 3, mr: "auto", ml: "auto" }}>Nos Valeurs</Heading>
      <Flex
        sx={{
          justifyContent: "space-around",
          flexWrap: "wrap",
          gap: 3,
          p: 24,
        }}
      >
        {[
          [
            "Culture et ambitions",
            "La culture de partage des connaissances qui anime notre groupe d’auditeurs & consultants pluridisciplinaires garantie des services de qualité.",
          ],
          [
            "Intégrité et indépendance",
            "Nos principes d’intégrité nous guident dans l’exercice de notre métier, ce qui fait de nous un partenaire en toute indépendance.",
          ],
          [
            "Continuité et confidentialité",
            "Nos relations avec nos clients s’inscrivent dans la durée et sont basées sur la confiance réciproque et l’offre de services sur mesure.",
          ],
          [
            "Qualité et excellence",
            "La qualité de notre équipe, la qualité de notre organisation et notre exigence vis-à-vis de nous-mêmes garantissent la qualité des services que nous offrons.",
          ],
        ].map(([value, desc]) => (
          <MotionFlex
            key={value}
            sx={{
              width: 300,
              height: 300,
              borderRadius: "49%",
              bg: "gray.1",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none",
              WebkitTapHighlightColor: "transparent",
              boxShadow: isMobile
                ? "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)"
                : undefined,
            }}
            variants={variants}
            whileHover={"hover"}
          >
            <Text
              sx={{
                textAlign: "center",
                position: "absolute",
                userSelect: "none",
                p: 4,
              }}
            >
              <b>{value}</b>
              <br />
              {desc}
            </Text>
            <Donut
              sx={{
                position: "absolute",
                userSelect: "none",
                cursor: isMobile ? "pointer" : "default",
                color: isMobile ? "primary" : "gray.2",
                ":hover": {
                  color: "primary",
                  cursor: "pointer",
                },
              }}
              value={1}
              size={300}
              strokeWidth={1}
            />
          </MotionFlex>
        ))}
      </Flex>
    </Flex>
  )
}

export default ValuesSection
